<!--主页导头部导航栏-->
<template>
  <div id="menu">
    <!-- FOR 大石 -->
    <a-menu mode="horizontal" class="menu" @select="handleclick" v-model="current" v-if="$platform.bivale=='HELC'">
      <a-menu-item v-for="(item, value) in list" :key="value">
        <span>{{ item.clientName }}</span>
      </a-menu-item>
    </a-menu>

    <!-- FOR 阿里云 -->
   <div class="container line ali-menu"  v-else-if="$platform.bivale=='ALIYUN'" >
    <swiper  :options="tswiperOption" pagination="true" ref="myTSwiper">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <div :class="selectCStyle(index)" @click="handleALiClick(index)">
            <div :title="item.clientName">
              <span>{{ item.clientName }}</span>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-scrollbar" slot="scrollbar"></div> 
      </swiper>
   </div>


   <div class="container">
      <swiper :options="swiperOption" pagination="true" ref="mySwiper">
        <swiper-slide v-for="(item, index) in twomenu" :key="index">
          <div :class="selectStyle(index)" @click="handlemenu(index)">
            <div :title="item.name">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-scrollbar" slot="scrollbar"></div> 
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      updateImg: require("../../../../public/images/index/updata01.png"),
      introImg: require("../../../../public/images/index/header01.png"),
      list: [],
      twomenu: [],
      language: "",
      isActive: Number(sessionStorage.getItem("twoNav")) || 0,
      current: [Number(sessionStorage.getItem("oneNav"))] || [0],
      spinning: true,
      tswiperOption: {
        slidesPerView: "auto",
        spaceBetween: 15,
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true,
        },
      },
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 15,
        scrollbar: {
          el: ".swiper-scrollbar",
          draggable: true,
        },
      },
    };
  },
  mounted() {
    // 调用获取一级导航栏菜单的方法
    this.getlist()
  },
  watch: {
    '$store.getters.oneMenu': {
      handler: function (newValue, oldValue) {
        this.list = newValue
      }
    },
    '$store.getters.twoMenu': {
      handler: function (newValue, oldValue) {
        this.twomenu = newValue
      }
    }
  },
  methods: {
    // 初始化三级导航栏
    getlist() {
      this.$store.dispatch('menu/getlist')
    },
    // 点击一级导航加载二三级导航
    handleclick(val) {
      let isActive = 0
      this.$store.commit('menu/HANDLE_ONEMENU', val.key)
      const cid = this.$store.getters.clientId
      const sid = this.$store.getters.sitegrId
      let route = this.$route.matched[2].path
      if (route !== '/information') { this.$router.push({ path: '/homepage/site',  query: { cid, sid } }) }
      sessionStorage.setItem('oneNav', val.key)
      sessionStorage.setItem('twoNav', isActive)
      this.isActive = 0
    },
    // 一级导航的样式切换方法
    selectCStyle(val) {
      return this.current == val ? 'activeClass' : 'twonav-li'
    },
    handleALiClick(val) {
      console.log('handleclick',val)
      let isActive = 0
      this.$store.commit('menu/HANDLE_ONEMENU', val)
      const cid = this.$store.getters.clientId
      const sid = this.$store.getters.sitegrId
      let route = this.$route.matched[2].path
      if (route !== '/information') { this.$router.push({ path: '/homepage/site',  query: { cid, sid } }) }
      sessionStorage.setItem('oneNav', val)
      sessionStorage.setItem('twoNav', isActive)
      this.isActive = 0
      this.current = val
    },
    selectStyle(val) {
      return this.isActive == val ? 'activeClass' : 'twonav-li'
    },
    // 二级导航选中方法
    handlemenu(val) {
      this.$store.commit('menu/HANDLE_TWOMENU', val)
      const cid = this.$store.getters.clientId
      const sid = this.$store.getters.sitegrId
      let route = this.$route.matched[2].path
      if (route !== '/information') { this.$router.push({ path: '/homepage/site',  query: { cid, sid } }) }
      sessionStorage.setItem('twoNav', val)
      this.isActive = val
    },
    cardselect() {},
  }
}
</script>

<style scoped>
li {
  list-style: none;
}
#menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
/* 一级导航背景色 */
.menu {
  background: #f3f3f7;
  height: 47px;
}
/* 二级导航容器 */
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* 二级导航ul */
.twonav {
  height: 53px;
  display: flex;
  align-items: center;
  margin: 0;
}
.swiper-container {
  width: 100%;
  padding: 15px 10px;
}
.swiper-slide {
  width: auto;
}
/* 二级导航未选中样式 */
.twonav-li {
  height: 32px;
  padding: 5px 10px;
  /* margin-right: 10px; */
  border-radius: 5px;
  color: #898989;
  background: #e4e9ef;
  cursor: pointer;
  white-space: nowrap;
  transition: 200ms;
}
.twonav-li:hover {
  color: #7682ce;
  background: #fff;
}
/* 二级导航选中样式 */
.activeClass {
  height: 32px;
  padding: 7px 10px;
  /* margin-right: 10px; */
  border-radius: 3px;
  color: #7682ce;
  background: #fff;
  box-shadow: 0px 3px 5px #bdbcbc;
  cursor: pointer;
  white-space: nowrap;
  transition: 200ms;
}
/* icon容器 */
.icon {
  height: 45px;
}
/* icon大小 */
img {
  height: 100%;
  margin-left: 15px;
}
div /deep/.ant-menu-item:hover{
  border-bottom: 2px solid transparent;
}
div /deep/.ant-menu-submenu:hover{
  border-bottom: 2px solid transparent;
}
.line{
  border-bottom: 2px solid #e4e9ef;
}
.ali-menu{
  font-size: 14px;
  font-family: "Microsoft YaHei";
}
</style>