<!--主页面布局-->
<template>
  <div class="index">
    <div class="header" @click="setScroll">
      <my-header></my-header>
    </div>
    <div class="main" ref="viewBox">
      <a-back-top :visibilityHeight="200" :target="() => this.$refs.viewBox" />
      <transition name="slide-fade" mode="out-in">
        <router-view class="view" :key="key"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import header from "./components/Header";

export default {
  methods: {
    setScroll() {
      this.$refs.viewBox.scrollTop = 0;
    },
  },
  computed: {
    key() {
      const path = this.$route.path
      if(path === '/homepage/site'){
        return this.$route.name !== undefined ? this.$route.name + + new Date(): this.$route + + new Date()
      }else{
        false
      }
    }
 },
  components: {
    "my-header": header
  }
}
</script>


<style scoped>
.index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  width: 100%;
  height: 120px;
  padding: 0px 20px;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 20px;
  overflow: auto;
}
.view {
  position: absolute;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>